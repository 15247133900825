export const timezone = process.env.VUE_APP_TIMEZONE ?? '';
export const googleMapApiKey = process.env.VUE_APP_GOOGLE_MAP_API_KEY ?? '';
export const refundUrl = process.env.VUE_APP_REFUND_URL ?? '';
export const ticketingUrl = process.env.VUE_APP_TICKETING_URL ?? '';
export const routeInformationUrl = process.env.VUE_APP_ROUTE_INFO_URL ?? '';
export const timetableResultsUrl = process.env.VUE_APP_TIMETABLE_RESULT_URL ?? '';
export const timetableBackupUrl = process.env.VUE_APP_TIMETABLE_BACKUP_URL ?? '';
export const smsAlertSignupUrl = process.env.VUE_APP_ALERT_LINK ?? '';
export const mobileAppDownloadUrl = process.env.VUE_APP_DOWNLOAD_LINK ?? '';
export const umbracoBaseUrl = process.env.VUE_APP_UMBRACO_CONSUMER_BASE || '';
export const dictionaryUrl = process.env.VUE_APP_DICTIONARY_URL ?? '';
export const stayUpdatedUrl = process.env.VUE_APP_STAY_UPDATED_URL || '';
export const assetBaseUrl = process.env.VUE_APP_ASSETS_BASE_URL || '';
